import IncidentTypes from "../constants/IncidentTypes";
import { RouteConstants } from "../constants/RouteConstants";
import TrackingAccuracyReportStore from "./trackingAccuracyReportStore";

class NonMLBTrackingAccuracyReportStore extends TrackingAccuracyReportStore {
  fetchTrackingAccuracyReport(date) {
    return this.veracityApi.fetchTrackingNonMLBAccuracyReport(date);
  }

  cancelTrackingAccuracyReport() {
    return this.veracityApi.cancelTrackingNonMLBAccuracyReport();
  }

  isTrackingTabActive() {
    return this.routerStore.isNonMLBTrackingAccuracyReportTab;
  }

  updateNewIncidentMetadata(game) {
    return this.veracityStore.updateNewIncidentMetadata(
      IncidentTypes.NON_MLB,
      game,
      this.get("date"),
      RouteConstants.NON_MLB_STATCAST,
    );
  }
}

export default NonMLBTrackingAccuracyReportStore;
